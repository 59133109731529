import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Switch from '@mui/material/Switch';
import { FormGroup, FormControlLabel, Typography } from "@mui/material";
import InputSpinner from './components/InputSpinner';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { RotatingLines } from 'react-loader-spinner';

import './styles.css';

import WebFont from 'webfontloader';

const POLL_INTERVAL = 5000;

const theme = createTheme({
    palette: {
        background: {
            default: '#004858',
        },
        primary: {
            main: '#18c1f7'
        },
        secondary: {
            main: '#ff6f00',
        }
    },       
    typography: {
      allVariants: {
        fontFamily: 'Red Hat Mono',
        textTransform: 'none',
        fontSize: 20,
      },
      h1: {
        fontFamily: 'Send Flowers',
        fontSize: 60,
        fontWeight: 400,
        color: '#ffffff',
      }
    }
});

export default function App() {
    
    // Store setTemp as a separate state variable, update on an interval
    // instead of every time the user changes the value.

    const [poolState, setPoolState] = useState({});
    const [userSetTemp, setUserSetTemp] = useState(100);

    const poolStateRef = useRef(poolState);
    const userSetTempRef = useRef(userSetTemp);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasAuthError, setHasAuthError] = useState(false);

    let controller = new AbortController();

    let requestsPending = 0;
    let timer = null;

    let fontsLoaded = false;
    let initialDataLoaded = false;

    
    // This acts as a "componentDidMount" and "componentWillUnmount" combined.
    useEffect(() => {
        //reconnectPoolApi();
        WebFont.load({
            google: {
                families: ['Red Hat Mono:400', 'Send Flowers:400']
            },
            active: () => { 
                console.log('fonts loaded');
                fontsLoaded = true; 
                if (initialDataLoaded) {
                    setIsLoading(false);
                }
            }
        });
    }, []);

    useEffect(() => {

        axios.interceptors.request.use((config) => {
            requestsPending += 1;
            console.log(`request: requests pending: ${requestsPending}`);
            return config;
          }, (error) => {
            if (error.code === 'ERR_CANCELED') {
                requestsPending -= 1;
            }
            console.log(`request interceptor error: ${error}`);
            return Promise.reject(error);
        });
        
        // Add a response interceptor
        axios.interceptors.response.use((response) => {
            requestsPending -= 1;
            console.log(`response: requests pending: ${requestsPending}`);
            return response;
          }, (error) => {
            if (error.code === 'ERR_CANCELED') {
                requestsPending -= 1;
            }
            if (error.response.status === 403) {
                setHasAuthError(true);
            } else {
                setHasError(true);
            }
            console.log(`response interceptor error: ${error}`);
            return Promise.reject(error);
        });
        
        getPoolState();

        return () => {
            controller.abort();
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
        }
    }, []);

    const reconnectPoolApi = () => {
        axios.post("/reset").then((response) => {
            console.log(`reconnectPoolApi: ${response.status}`);
        }
        ).catch((error) => {
            console.log(`reconnectPoolApi: ${error}`);
        });
    }

    const getPoolState = async () => {

        //console.log(`  getPoolState() ${JSON.stringify(poolState)}`);

        if (requestsPending > 0) {
            return;
        }

        if (initialDataLoaded && userSetTempRef.current != poolStateRef.current.setTemp) {
            console.log(`pool setTemp: ${poolStateRef.current.setTemp} user setTemp: ${userSetTempRef.current}`);
            // If the user has changed the setTemp, update the server.
            changePoolState({ setTemp: userSetTempRef.current });
            userSetTempRef.current = userSetTemp;
            return;
        }

        try {
            let response = await axios.get("/status", { signal: controller.signal });
            if (response.status === 200) {
                console.log(`updateState: ${JSON.stringify(response.data.state)}`);
                setPoolState(response.data.state);
                setUserSetTemp(response.data.state.setTemp);
                poolStateRef.current = response.data.state;
                userSetTempRef.current = response.data.state.setTemp;
                initialDataLoaded = true;
                if (fontsLoaded) {
                    setIsLoading(false);
                }
                if (hasError) {
                    setHasError(false);
                }
            }
            timer = setTimeout(() => getPoolState(), POLL_INTERVAL);
        } catch (err) {
            if (err.code === 'ERR_CANCELED') {
                console.log('request canceled');
            } else {
                console.log(err);
            }
        }
    }

    const changePoolState = async (update) => {
        // If a status request is in progress, cancel it.
        // VERY difficult to test this, not sure it's necessary?
        if (requestsPending > 0) {
            console.log('aborting request');
            controller.abort();
            controller = new AbortController();
        }

        try {
            let response = await axios.post("/set", update, { signal: controller.signal });
            if (response.status === 200) {
                console.log(`changeState: ${JSON.stringify(response.data.state)}`);
                setPoolState(response.data.state);
                setUserSetTemp(response.data.state.setTemp);
                poolStateRef.current = response.data.state;
                userSetTempRef.current = response.data.state.setTemp;
                if (hasError) {
                    setHasError(false);
                }
            }
            // Cancel the timer and restart it.
            clearTimeout(timer);
            timer = setTimeout(() => getPoolState(), POLL_INTERVAL);
        } catch (err) {
            if (err.code === 'ERR_CANCELED') {
                console.log('request canceled');
            } else {
                console.log(err);
            }
        }
    }

    console.log(`poolState: ${JSON.stringify(poolState)}`);

    return (
        <ThemeProvider theme={theme}>
            { hasAuthError ? <div/> :
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                <Typography variant="h1" component="h1" align="center">Pool Boy</Typography>
                { hasError && <div style={{color: 'red', textAlign: 'center'}}>
                        <strong>Error communicating with server, please refresh the page.</strong>
                    </div> }
                <div className='container'>
                { isLoading 
                ?   <RotatingLines
                        className='spinner'
                        strokeColor='#18c1f7'
                        strokeWidth='4'
                        animationDuration='1.0'
                        width='96'
                        visible={true}
                        ariaLabel='rotating-lines-loading' />
                :   
                    <div>
                        <strong>LAST TEMP: {poolState.currentTemp}</strong>
                        <hr class='rounded' width='80%'/>
                        <FormGroup>
                            <FormControlLabel control={
                                <Switch
                                    color='primary'
                                    checked={poolState.spa === 'on'}
                                    onChange={(e, c) => { 
                                        // If the user turns off the spa, turn off the jets and air blower.
                                        if (c == 'off') {
                                            changePoolState({ spa: 'off', jets: 'off', airBlower: 'off' });
                                        } else {
                                            // Turn off cleaner when turning on spa.
                                            changePoolState({ spa: c ? 'on' : 'off', cleaner: 'off' });
                                        }
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} label="SPA ON/OFF" labelPlacement='bottom'/>
                        </FormGroup>
                        <hr class='rounded' width='80%'/>
                        <FormGroup>
                            <FormControlLabel control={<InputSpinner
                                disabled={poolState.spa === 'off'}
                                editable={false}
                                fontFamily={theme.typography.allVariants.fontFamily}
                                fontSize={theme.typography.allVariants.fontSize}
                                width={200}
                                max={104}
                                min={40}
                                step={1}
                                colorLeft={theme.palette.primary.main}
                                colorRight={theme.palette.primary.main}
                                value={userSetTemp}
                                onChange={(num) => {
                                    console.log(num);
                                    setUserSetTemp(num);
                                    userSetTempRef.current = num;
                                }}
                            />} label="SET TEMP" labelPlacement='bottom'/>
                        </FormGroup>
                        <hr class='rounded' width='80%'/>
                        <div className='switch-container'>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        //classes={iosStyles}
                                        color='primary'
                                        disabled={poolState.spa === 'off'}
                                        checked={poolState.jets === 'on'}
                                        onChange={(e, c) => { 
                                            changePoolState({ jets: c ? 'on' : 'off' });
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="JETS" className="formControl"/>
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        //classes={iosStyles}
                                        color='primary'
                                        disabled={poolState.spa === 'off'}
                                        checked={poolState.airBlower === 'on'}
                                        onChange={(e, c) => { 
                                            changePoolState({ airBlower: c ? 'on' : 'off' });
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="AIR BLOWER" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        //classes={iosStyles}
                                        color='primary'
                                        checked={poolState.spaLights === 'on'}
                                        onChange={(e, c) => { 
                                            changePoolState({ spaLights: c ? 'on' : 'off' });
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="SPA LIGHT" />
                            </FormGroup>
                            {/* Temp remove pool light controls.
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        //classes={iosStyles}
                                        color='primary'
                                        checked={poolState.poolLights === 'on'}
                                        onChange={(e, c) => { 
                                            changePoolState({ poolLights: c ? 'on' : 'off' });
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="POOL LIGHTS" />
                            </FormGroup>
                            */}
                        </div>
                    </div> }
               </div>
            </StyledEngineProvider> }
        </ThemeProvider>
    );
}